<!-- 耕雲導師永恒的怀念 -->
<template>
    <div class="memory">
        <table border="1" cellspacing="0">
            <tr v-for="(item,index) in content" :key="index">
                <td class="number">{{index+1}}</td>
                <td @click="linkToDetail(item)" class="articleName">{{item.news_title}}</td>
                <td>{{item.news_author}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import { getCateList, getForeverRemembranceList } from "../../api/apis";
export default {
    name: "",
    components: {},

    data() {
        return {
            content: []
        };
    },
    methods: {
        linkToDetail(item) {
			window.open(this.$BaseURL+'/articleDetail?name=memory&id='+item.id+'&title='+this.title);
            // this.$router.push({
            //     path: "/articleDetail",
            //     query: { name: "memory", id: item.id, title: this.title }
            // });
        }
    },
    created() {
        getCateList().then(res => {
            let id = res[0][6].id;
            getForeverRemembranceList(id).then(res => {
                this.content = res;
            });
        });
    }
};
</script>

<style lang="stylus" scoped>
.ta-l {
    text-align: left;
}

.pl5 {
    padding-left: 5px;
}

.memory {
    color: #333333;
    width: 778px;
    /* height: 726px; */
    margin: 20px 5px;

    .number {
        width: 77px;
        text-align: center;
    }

    .articleName {
        width: 424px;
    }

    .articleName:hover {
        color: #1482d9;
        cursor: pointer;
    }
}

table, td, tr, th {
    border: 1px solid #ACACAC;
}

table {
    width: 100%;
    font-size: 15px;

    td {
        line-height: 30px;
        text-align: left;
        padding-left: 5px;
    }
}
</style>